import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { SportEventOffersV2FragmentDoc } from './SportEventOffersV2.fragment';
export type SportEventV2OverviewPageFragment = {
	__typename?: 'Query';
	sportEventBucketsV2: {
		__typename?: 'SportEventBucketsConnectionV2';
		pageInfo: {
			__typename?: 'PageInfo';
			startCursor: string;
			endCursor: string;
			hasPreviousPage: boolean;
			hasNextPage: boolean;
		};
		edges?: Array<{
			__typename?: 'SportEventBucketsEdgeV2';
			key: {
				__typename?: 'SportEventAggregationKeyV2';
				competitionStage?: {
					__typename?: 'SportCompetitionStageV2';
					name: Types.SportCompetitionStageName;
					number?: number | null;
				} | null;
				competition?: {
					__typename?: 'SportCompetitionV2';
					id: string;
					sport: Types.SportType;
					content: {
						__typename?: 'SportCompetitionContentV2';
						name: string;
						fullPath: string;
						iconUrl?: string | null;
					};
				} | null;
				sport?: { __typename?: 'Sport'; type: Types.SportType } | null;
			};
			node: {
				__typename?: 'SportEventsConnectionV2';
				totalCount: number;
				pageInfo: {
					__typename?: 'PageInfo';
					startCursor: string;
					endCursor: string;
					hasPreviousPage: boolean;
					hasNextPage: boolean;
				};
				edges?: Array<{
					__typename?: 'SportEventsEdgeV2';
					cursor: string;
					node:
						| {
								__typename?: 'MultiStepSportEvent';
								sport: Types.SportType;
								offerCount: number;
								watchNowOffer?: {
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								} | null;
								offers: Array<{
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								}>;
						  }
						| {
								__typename?: 'SingleStepSportEvent';
								sport: Types.SportType;
								offerCount: number;
								watchNowOffer?: {
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								} | null;
								offers: Array<{
									__typename?: 'SportOffer';
									monetizationType: Types.MonetizationType;
									presentationType: Types.PresentationType;
									audioLanguage?: string | null;
									standardWebURL?: string | null;
									sportObjectType: Types.SportObjectType;
									package: {
										__typename?: 'Package';
										id: string;
										packageId: number;
										technicalName: string;
										clearName: string;
										monetizationTypes: Array<Types.MonetizationType>;
									};
								}>;
						  };
				}> | null;
			};
		}> | null;
	};
};

export const SportEventV2OverviewPageFragmentDoc = gql`
	fragment SportEventV2OverviewPage on Query {
		sportEventBucketsV2(
			country: $country
			first: $first
			bucketSize: $bucketSize
			filter: $filter
			groupBy: $groupBy
			after: $after
			timezoneOffset: $timezoneOffset
			sortBy: $sortBy
			sortOrder: $sortOrder
		) {
			pageInfo {
				startCursor
				endCursor
				hasPreviousPage
				hasNextPage
			}
			edges {
				key {
					competitionStage {
						name
						number
					}
					competition {
						id
						sport
						content(country: $country, language: $language) {
							name
							fullPath
							iconUrl
						}
					}
					sport {
						type
					}
				}
				node {
					totalCount
					pageInfo {
						startCursor
						endCursor
						hasPreviousPage
						hasNextPage
					}
					edges {
						cursor
						node {
							sport
							...SportEventOffersV2
						}
					}
				}
			}
		}
	}
	${SportEventOffersV2FragmentDoc}
`;
