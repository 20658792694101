
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import HiddenHorizontalScrollbar from '@/components/HiddenHorizontalScrollbar.vue';
import GlobalSportsV2HeaderCard from '@/components/home/global/GlobalSportsV2HeaderCard.vue';
import MultiStepEventEncounter from '@/components/sports/common/event/MultiStepEventEncounter.vue';

import {
	MultiStepSportEvent,
	ObjectType,
	Platform,
	SingleStepSportEvent,
	SportCompetitorV2,
	SportEventAggregation,
	SportEventStatus,
	SportType,
} from '@/@types/graphql-types';
import { WebLocale } from '@/enums/web-locale';
import { UpcomingEvent } from '@/interfaces/sport-graphql';

import {
	GetSingleStepSportOverviewDocument,
	GetSingleStepSportOverviewQuery,
	GetSingleStepSportOverviewQueryVariables as SingleStepSportVariables,
} from '@/pages/graphql/queries/GetSingleStepSportOverview.query';

import { shiftDateWithTimezone } from '@/helpers/date-helper';
import { LocaleResponse } from '@/helpers/locale-helper';
import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { toastError } from '@/helpers/toast/toast-helper';
import {
	SnowplowContext,
	SnowplowPageTypeContext,
	SnowplowSportContext,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { usePersistedTodaysDate } from '@/helpers/composables/usePersistedTodaysDate';

type TimeGroup = 'live' | 'today' | 'upcoming';

const Language = namespace('language');
const Routing = namespace('routing');

// depending on where the JS machine is, this can be different.
// so we force it by comparing it to the timezone of the current webLocale.
const changeTimezone = (timeZone: string, targetDate = new Date()) => {
	// we can hard code en-US because in the end we're only interested in the Date object
	const localisedDateString = targetDate.toLocaleDateString('en-US', { timeZone, timeZoneName: 'short' });
	return new Date(localisedDateString);
};

const isToday = (today: Date, targetDate: Date) => {
	return today.toDateString() === targetDate.toDateString();
};

const competitorToLineItem = (competitor: SportCompetitorV2) => ({
	text: competitor!.content.name,
	iconUrl: competitor!.content.iconUrl as string,
	iconBlurHash: competitor!.content.iconBlurHash as string,
});

const apolloUpdateData = (data: GetSingleStepSportOverviewQuery) =>
	(data.sportEventBucketsV2?.edges ?? [])
		.map((edge: any) => edge.node?.edges ?? [])
		.flat()
		.map((edge: any) => edge.node);

@Component({
	name: 'GlobalSportsV2Cards',
	components: {
		HiddenHorizontalScrollbar,
		GlobalSportsV2HeaderCard,
		MultiStepEventEncounter,
	},
	apollo: {
		upcomingFootballEvents: {
			query: GetSingleStepSportOverviewDocument,
			variables(): SingleStepSportVariables {
				return this.getQueryVariables(SportType.Football);
			},
			update: (data: GetSingleStepSportOverviewQuery) => apolloUpdateData(data),
			skip() {
				return this.$route.name !== 'app.home';
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Upcoming Football Events Data]:',
					{ error: error, where: 'Component: GlobalSportsV2Cards' },
					'error'
				);
				process.client ? this.showErrorToast() : null;
			},
		},
		upcomingAmericanFootballEvents: {
			query: GetSingleStepSportOverviewDocument,
			variables(): SingleStepSportVariables {
				return this.getQueryVariables(SportType.AmericanFootball);
			},
			update: (data: GetSingleStepSportOverviewQuery) => apolloUpdateData(data),
			skip() {
				return this.$route.name !== 'app.home';
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Upcoming American Football Events Data]:',
					{ error: error, where: 'Component: GlobalSportsV2Cards' },
					'error'
				);
				process.client ? this.showErrorToast() : null;
			},
		},
		upcomingTennisEvents: {
			query: GetSingleStepSportOverviewDocument,
			variables(): SingleStepSportVariables {
				return this.getQueryVariables(SportType.Tennis);
			},
			update: (data: GetSingleStepSportOverviewQuery) => apolloUpdateData(data),
			skip() {
				return this.$route.name !== 'app.home';
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Upcoming Tennis Football Events Data]:',
					{ error: error, where: 'Component: GlobalSportsV2Cards' },
					'error'
				);
				process.client ? this.showErrorToast() : null;
			},
		},
		upcomingBaseballEvents: {
			query: GetSingleStepSportOverviewDocument,
			variables(): SingleStepSportVariables {
				return this.getQueryVariables(SportType.Baseball);
			},
			update: (data: GetSingleStepSportOverviewQuery) => apolloUpdateData(data),
			skip() {
				return this.$route.name !== 'app.home';
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Upcoming Baseball Football Events Data]:',
					{ error: error, where: 'Component: GlobalSportsV2Cards' },
					'error'
				);
				process.client ? this.showErrorToast() : null;
			},
		},
		upcomingBasketballEvents: {
			query: GetSingleStepSportOverviewDocument,
			variables(): SingleStepSportVariables {
				return this.getQueryVariables(SportType.Basketball);
			},
			update: (data: GetSingleStepSportOverviewQuery) => apolloUpdateData(data),
			skip() {
				return this.$route.name !== 'app.home';
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Upcoming Basketball Football Events Data]:',
					{ error: error, where: 'Component: GlobalSportsV2Cards' },
					'error'
				);
				process.client ? this.showErrorToast() : null;
			},
		},
		upcomingIceHockeyEvents: {
			query: GetSingleStepSportOverviewDocument,
			variables(): SingleStepSportVariables {
				return this.getQueryVariables(SportType.IceHockey);
			},
			update: (data: GetSingleStepSportOverviewQuery) => apolloUpdateData(data),
			skip() {
				return this.$route.name !== 'app.home';
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Upcoming Ice Hockey Football Events Data]:',
					{ error: error, where: 'Component: GlobalSportsV2Cards' },
					'error'
				);
				process.client ? this.showErrorToast() : null;
			},
		},
	},
	setup() {
		const { todayDate } = usePersistedTodaysDate();
		return {
			todayDate,
		};
	},
})
export default class GlobalSportsV2Cards extends Vue {
	upcomingFootballEvents: SingleStepSportEvent[] = [];
	upcomingAmericanFootballEvents: SingleStepSportEvent[] = [];
	upcomingTennisEvents: SingleStepSportEvent[] = [];
	upcomingBaseballEvents: SingleStepSportEvent[] = [];
	upcomingBasketballEvents: SingleStepSportEvent[] = [];
	upcomingIceHockeyEvents: SingleStepSportEvent[] = [];

	SportType = SportType;

	domainSessionId = '' as string | undefined;

	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];

	declare todayDate: Date;

	@Language.Getter localeObject: LocaleResponse;
	@Language.Getter language: string;
	@Language.Getter locale: string;
	@Language.Getter country: string;
	@Language.State webLocale: WebLocale;

	@Routing.State lastRoute: any;

	get sportOrder() {
		switch (this.webLocale) {
			case WebLocale.us:
				return [
					{
						name: 'American Football',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/american_football.png`,
						sport: SportType.AmericanFootball,
						events: this.getSportEvents(this.upcomingAmericanFootballEvents),
					},
					{
						name: 'Basketball',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/basketball.png`,
						sport: SportType.Basketball,
						events: this.getSportEvents(this.upcomingBasketballEvents),
					},
					{
						name: 'Baseball',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/baseball.png`,
						sport: SportType.Baseball,
						events: this.getSportEvents(this.upcomingBaseballEvents),
					},
					{
						name: 'Ice Hockey',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/ice_hockey.png`,
						sport: SportType.IceHockey,
						events: this.getSportEvents(this.upcomingIceHockeyEvents),
					},
					{
						name: 'Football',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/football.png`,
						sport: SportType.Football,
						events: this.getSportEvents(this.upcomingFootballEvents),
					},
					{
						name: 'Tennis',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/tennis.png`,
						sport: SportType.Tennis,
						events: this.getSportEvents(this.upcomingTennisEvents),
					},
				];
			default:
				return [
					{
						name: 'Football',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/football.png`,
						sport: SportType.Football,
						events: this.getSportEvents(this.upcomingFootballEvents),
					},
					{
						name: 'Tennis',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/tennis.png`,
						sport: SportType.Tennis,
						events: this.getSportEvents(this.upcomingTennisEvents),
					},
					{
						name: 'American Football',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/american_football.png`,
						sport: SportType.AmericanFootball,
						events: this.getSportEvents(this.upcomingAmericanFootballEvents),
					},
					{
						name: 'Basketball',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/basketball.png`,
						sport: SportType.Basketball,
						events: this.getSportEvents(this.upcomingBasketballEvents),
					},
					{
						name: 'Baseball',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/baseball.png`,
						sport: SportType.Baseball,
						events: this.getSportEvents(this.upcomingBaseballEvents),
					},
					{
						name: 'Ice Hockey',
						icon: `/${ASSETS_DIR}/img/sports/icons/sport/ice_hockey.png`,
						sport: SportType.IceHockey,
						events: this.getSportEvents(this.upcomingIceHockeyEvents),
					},
				];
		}
	}

	showErrorToast() {
		toastError({ message: this.$t('WEBAPP_ALERT_ERROR_TITLE') as string, duration: 2000 });
	}

	getSportEvents(sport: SingleStepSportEvent[]) {
		return this.getEvents(sport)[this.getEventTimeGroup(sport)];
	}

	getEvents(events: SingleStepSportEvent[]): Record<TimeGroup, UpcomingEvent[]> {
		const allEvents = events.map(event => {
			const lineItems = this.getLineItems(event);
			const competitionStageText = this.getCompetitionStageText(event);
			const localStartAt = changeTimezone(this.localeObject.timezone, new Date(event.content.startAt)); // needs to be put in current webLcoale's timezone
			const startAt = new Date(event.content.startAt);
			const fullPath = this.getFullPath(event);

			return {
				id: event.id,
				lineItems,
				fullPath,
				startAt: startAt,
				localStartAt: localStartAt,
				sport: event.sport,
				competition: {
					text: event.competition?.content?.name,
					fullPath: event.competition?.content?.fullPath,
				},
				competitionStageText,
				offers: event?.offers,
				watchNowOffer: event.watchNowOffer,
				sportEvent: event, // used for snowplow context and tracking
			} as UpcomingEvent;
		});
		const localeToday = changeTimezone(this.localeObject.timezone, this.todayDate);
		const liveMatches = allEvents.filter(event => event?.sportEvent?.content?.status === SportEventStatus.Live);
		const todayMatches = allEvents.filter(event => isToday(localeToday, event.localStartAt));
		const upcomingMatches = allEvents.filter(event => !isToday(localeToday, event.localStartAt));

		return {
			live: liveMatches,
			today: todayMatches,
			upcoming: upcomingMatches,
		};
	}

	getEventTimeGroup(events: SingleStepSportEvent[]): 'live' | 'today' | 'upcoming' {
		if (this.getEvents(events).live.length > 0) {
			return 'live';
		} else if (this.getEvents(events).today.length > 0) {
			return 'today';
		} else {
			return 'upcoming';
		}
	}

	getQueryVariables(sport: SportType): SingleStepSportVariables {
		return {
			platform: Platform.Web,
			language: this.language,
			country: this.country,
			first: 1,
			bucketSize: 10, // @todo check if we want to keep it for multi sport queries
			groupBy: SportEventAggregation.Sport,
			filter: {
				startAt: {
					from: this.todayDate.toISOString(),
					to: null,
				},
				competitionIds: [],
				sports: [sport],
			},
			timezoneOffset: 0,
		};
	}

	handleCardClick(event: any) {
		const sportContext = SnowplowSportContext.fromSportEventV2(
			event.sport,
			ObjectType.SportEvent,
			event.sportEvent
		);

		const pageContext = new SnowplowPageTypeContext('VIEW_HOME_GLOBAL', this.country, this.language);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'event_clicked',
				property: 'card-v2',
			},
			[sportContext, pageContext, ...this.additionalContexts]
		);
	}

	private getEventContent(event: SingleStepSportEvent | MultiStepSportEvent) {
		return 'parent' in event
			? (event as MultiStepSportEvent).parent!.content
			: (event as SingleStepSportEvent).content;
	}

	private getFullPath(event: SingleStepSportEvent | MultiStepSportEvent) {
		return this.getEventContent(event).fullPath;
	}

	// depending on sport, it can the team's name or the course of the event.
	// that's why it's called line items.
	private getLineItems(event: SingleStepSportEvent | MultiStepSportEvent): UpcomingEvent['lineItems'] {
		// MultiStepSportEvent
		if ('parent' in event) {
			return [{ text: event.parent?.content.name ?? '' }];
		}

		// SingleStepSportEvent
		const { competitors } = <SingleStepSportEvent>event;
		const teams = [competitors.homeTeam, competitors.awayTeam];

		return teams
			.map(team => {
				// tennis doubles
				if (event.sport === SportType.Tennis && team.competitors.length === 2) {
					return team.competitors.map(competitor => competitorToLineItem(competitor));
				}

				// non-tennis teams have only 1 entry
				return competitorToLineItem(team.competitors.find(Boolean)!);
			})
			.flat();
	}

	private getCompetitionStageText(event: SingleStepSportEvent | MultiStepSportEvent) {
		const { name, number } = event.content?.competitionStage || {};

		switch (event.sport) {
			case SportType.FormulaRacing:
				// capitalise racing (e.g. Practice, Qualifying, ...)
				const formulaRacingName = `${name?.slice(0, 1).toUpperCase()}${name?.slice(1).toLowerCase()}`;
				return [formulaRacingName, number].filter(item => item !== null).join(' ');

			default:
				if (name === 'UNDEFINED' || !name) return '';
				return this.$t(`SPORTS_WEBAPP_${name}`, { value: number });
		}
	}
}
